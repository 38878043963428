import { useLazyQuery } from '@apollo/client'
import {
  Cart,
  Category,
  LineItem,
  LineItemMode,
  ProductData,
  ProductVariant,
  Query,
  RawProductAttribute,
} from '@danone-global/ct/interfaces'
import { GET_PRODUCT_CATEGORIES } from '@danone-global/internal/graphql'
import {
  analytics,
  ANALYTICS_CATEGORY_LIMIT,
  filterGiftLineItem,
  getActiveCart,
  getCustomValue,
  isValue,
  limitArrayCharacters,
  SET_QUICK_VIEW_STATE,
  UPDATE_ACTIVE_CART,
  useCart,
  useCore,
  useMutation,
} from '@danone-global/internal/react/core'
import { useChannelId } from '@danone-global/internal/react/modules/stock'
import React from 'react'

export const localisedProductDescription = (
  item: ProductData,
  locale: string,
): string => {
  const localisedDescription = item?.descriptionAllLocales?.find(
    (description) => description.locale === locale,
  )

  if (localisedDescription) {
    return localisedDescription.value
  }

  return item?.descriptionAllLocales?.length > 0
    ? item.descriptionAllLocales[0].value
    : ''
}

export const productStructuredData = (
  current: ProductData,
  storeName: string,
  sku: string,
  locale: string,
  url: string,
) => {
  if (!current) return null

  const localisedDescription =
    localisedProductDescription(current, locale) ?? current?.description

  const structuredData = {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    name: current?.name,
    image: current?.variant?.images?.map((item) => item.url),
    description: localisedDescription,
    sku: sku,
    brand: {
      '@type': 'Brand',
      name: storeName ?? 'Danone',
    },
    offers: {
      '@type': 'Offer',

      itemCondition: 'https://schema.org/NewCondition',
      availability: current?.variant?.availability?.channels?.results?.some(
        (channel) => channel.availability.isOnStock,
      )
        ? 'https://schema.org/InStock'
        : 'https://schema.org/OutOfStock',
    },
  }
  const price = (current?.variant?.price?.value?.centAmount / 100).toFixed(2)
  if (price) {
    structuredData.offers['price'] = price
    structuredData.offers['priceCurrency'] =
      current?.variant?.price?.value.currencyCode
  }
  if (url) {
    structuredData.offers['url'] = url
  }

  return structuredData
}

export const useProductUrl = (attributesRaw: RawProductAttribute[]): string => {
  const { localeConfig: { locale },
  } = useCore()

  return React.useMemo(() => {
    const attribute = attributesRaw.find(
      (attribute) => attribute.name === 'productDetailPageUrl',
    )

    if (attribute && locale in attribute.value) {
      return attribute.value[locale]
    }

    return null
  }, [attributesRaw, locale])
}

export const useAddProductToCart = ({
  withQuickView = true,
  isUpSell = false,
  withAnalytics = true,
  cart,
  subscriptionId,
}: {
  withQuickView?: boolean
  isUpSell?: boolean
  withAnalytics?: boolean
  cart: Cart | undefined
  subscriptionId?: string
}) => {
  const fallbackChannelId = useChannelId()
  const addMultipleProductsToCart = useAddMultipleProductsToCart({
    isUpSell,
    withAnalytics,
    withQuickView,
    cart,
    subscriptionId,
  })

  return React.useCallback(
    async (
      productVariant: ProductVariant,
      priceChannelId = fallbackChannelId,
      stockChannelId = fallbackChannelId,
      quantity = 1,
      name = '',
      categories = [],
      patientName = ''
    ) => {
      return await addMultipleProductsToCart([
        {
          productVariant,
          priceChannelId,
          stockChannelId,
          quantity,
          name,
          categories,
          patientName,
        },
      ])
    },
    [addMultipleProductsToCart, fallbackChannelId],
  )
}

export type CartProductItem = {
  productVariant: ProductVariant | undefined | null
  quantity?: number
  priceChannelId?: string | undefined
  stockChannelId?: string | undefined
  name: string
  categories: Category[]
  patientName?: string | undefined
}

export const useAddMultipleProductsToCart = ({
  isUpSell = false,
  withAnalytics = true,
  withQuickView = true,
  cart = undefined,
  subscriptionId = undefined,
} = {}) => {
  const [showQuickView] = useMutation(SET_QUICK_VIEW_STATE)
  const [updateCart] = useMutation(UPDATE_ACTIVE_CART)
  const fallbackChannelId = useChannelId()
  const core = useCore()

  return React.useCallback(
    async (productList: CartProductItem[]) => {

      const oldCart = await getActiveCart(core)

      const skus = productList.map((p) => p.productVariant.sku).filter(isValue)

      const actions = productList.map((product) =>
        addToCartAction(
          product.productVariant,
          product.quantity,
          product.priceChannelId ?? fallbackChannelId,
          product.stockChannelId ?? fallbackChannelId,
          cart,
          subscriptionId,
          product.patientName,
        ),
      )
      await updateCart({ variables: { actions } })

      if (withAnalytics) {
        const analyticEvents = productList.flatMap((product) =>
          [
            {
              action: 'ADD_LINE_ITEM',
              event: "add_to_cart",
              ecommerce: {
                currency: product.productVariant.price?.value?.currencyCode,
                value:
                  product.productVariant.price?.value?.centAmount / 100 || 0,
                items: [
                  {
                    item_id: product.productVariant.sku,
                    item_name: product.name,
                    price:
                      product.productVariant.price?.value?.centAmount / 100 || 0,
                    quantity: product.quantity,
                    item_category: limitArrayCharacters(
                      product.categories?.map((i) => i.name).join(',') || '',
                      ANALYTICS_CATEGORY_LIMIT,
                    ),
                    discount: product.productVariant.price?.discounted?.value?.centAmount / 100 || 0,
                    affiliation: core.config.storeName,
                    item_variant: product.productVariant?.key
                  },
                ],
              }
            },
            isUpSell
              ? {
                action: 'ADD_UPSELL_PRODUCT',
                event: 'add_upsell_product',
                ecommerce: {
                  items: [
                    {
                      item_id: product.productVariant.sku,
                      item_name: product.name,
                      price:
                        product.productVariant.price?.value?.centAmount / 100,
                      quantity: product.quantity,
                      item_category: limitArrayCharacters(
                        product.categories?.map((i) => i.name).join(',') || '',
                        ANALYTICS_CATEGORY_LIMIT,
                      ),
                      discount: product.productVariant.price?.discounted?.value?.centAmount / 100 || 0,
                      affiliation: core.config.storeName,
                      item_variant: product.productVariant?.key
                    },
                  ],
                }
              }
              : undefined,
          ].filter(isValue),
        )

        for (const event of analyticEvents) {
          analytics.push(event)
        }
      }

      const newCart = await getActiveCart(core)

      const newGiftLineItemSkus = newCart.lineItems
        .filter(filterGiftLineItem(core.features))
        .filter(
          (l) =>
            l.lineItemMode === LineItemMode.GiftLineItem &&
            !oldCart.lineItems.some((l2) => l2.id === l.id),
        )
        .map((l) => l.variant.sku)

      if (withQuickView && actions.length > 0) {
        await showQuickView({
          variables: {
            open: true,
            skus,
            giftSkus: newGiftLineItemSkus,
          },
        })
      }
      return skus
    },
    [
      updateCart,
      fallbackChannelId,
      isUpSell,
      showQuickView,
      withQuickView,
      withAnalytics,
      core,
      subscriptionId,
    ],
  )
}
/* istanbul ignore next */
export const useUpdateQuantityFromCart = ({
  
  withQuickView = true,
  withAnalytics,

  subscriptionId = undefined,

  setCartError,
}: {

  withQuickView?: boolean
  withAnalytics: boolean

  subscriptionId: any

  setCartError?: (error: string) => void
}) => {
  const [updateCart] = useMutation(UPDATE_ACTIVE_CART)
  const [showQuickView] = useMutation(SET_QUICK_VIEW_STATE)
  const fallbackChannelId = useChannelId()

  const defaultChannelId = useChannelId()

  const { config, localeConfig } = useCore()
  const [cart] = useCart()
  const core = useCore()


  const [getProductCategories] = useLazyQuery<Query>(GET_PRODUCT_CATEGORIES)

  return async (quantity:number, lineItem:any, productList: any[]) => {
    if (!lineItem) {
      return
    }
  
    const priceChannelId = lineItem?.distributionChannel?.id || defaultChannelId
    const stockChannelId = lineItem?.supplyChannel?.id || defaultChannelId
    
    
    const { data: productCategoryData } = await getProductCategories({
      variables: {
        skus: cart?.lineItems ? cart.lineItems.map((i) => i.variant?.sku):[],
        locale: localeConfig.locale,
      },
    })

    const oldQuantity = (cart.lineItems || []).find(
      (i) => i.id === lineItem.id,
    )?.quantity

    const oldCart = await getActiveCart(core)
    const skus = productList.map((p) => p.variant.sku)


    const actions = []
    await updateCart({
      variables: {
        actions:
          addToCartActions(
            lineItem,
            quantity,
            priceChannelId,
            stockChannelId,
          )

      }
    })
    .then(() => {
      if (setCartError) {
        setCartError('')
      }
    })
    .catch((error) => {
      if (
        setCartError &&
        error?.graphQLErrors?.[0]?.extensions?.code === 'InvalidOperation'
      ) {
        setCartError(
          error?.graphQLErrors?.[0]?.extensions?.localizedMessage?.[
          localeConfig.locale
          ] || error.message,
        )
      }
    })
    if (withAnalytics) {
      const categories =
        productCategoryData?.products?.results
          ?.find((i) => i.id === lineItem.productId)
          ?.masterData?.current?.categories.map((i) => i.name) || []

      const categoriesString = limitArrayCharacters(
        categories.join(','),
        ANALYTICS_CATEGORY_LIMIT,
      )

      if (quantity > 0) {
        analytics.push({
          action: 'ADD_LINE_ITEM',
          event: 'add_to_cart',
          ecommerce: {
            currency: lineItem?.price?.value?.currencyCode,
            value: Number(lineItem.price?.value?.centAmount) / 100,
            items: [
              {
                item_id: lineItem.variant.sku,
                item_name: lineItem.nameAllLocales?.find(
                  (name) => name.locale === cart?.locale,
                )?.value,
                price: Number(lineItem.price?.value?.centAmount) / 100,
                quantity,
                item_category: categoriesString,
                discount:
                  Number(lineItem.price?.discounted?.value?.centAmount) / 100 ||
                  0,
                affiliation: config.storeName,
                item_variant: lineItem?.variant?.key,
              },
            ],
          },
        })
      } else {
        analytics.push({
          action: 'REMOVE_LINE_ITEM',
          event: 'remove_from_cart',
          ecommerce: {
            currency: lineItem?.price?.value?.currencyCode,
            value: Number(lineItem.price?.value?.centAmount) / 100,
            items: [
              {
                item_id: lineItem.variant.sku,
                item_name: lineItem.nameAllLocales?.find(
                  (name) => name.locale === cart?.locale,
                )?.value,
                price: Number(lineItem.price?.value?.centAmount) / 100,
                quantity: oldQuantity,
                item_category: categoriesString,
                affiliation: config.storeName,
                item_variant: lineItem?.variant?.key,
                discount:
                  Number(lineItem.price?.discounted?.value?.centAmount) / 100 ||
                  0,
              },
            ],
          },
        })
      }
    }
    const newCart = await getActiveCart(core)

    const newGiftLineItemSkus = newCart.lineItems
      .filter(filterGiftLineItem(core.features))
      .filter(
        (l) =>
          l.lineItemMode === LineItemMode.GiftLineItem &&
          !oldCart.lineItems.some((l2) => l2.id === l.id),
      )
      .map((l) => l.variant.sku)

    if (withQuickView) {
      await showQuickView({
        variables: {
          open: true,
          skus,
          giftSkus: newGiftLineItemSkus,
        },
      })
    }
    return skus
  
}
}
  /* istanbul ignore next */ 
const addToCartActions = (
  lineItem: LineItem,
  quantity: number,
  priceChannelId: string,
  stockChannelId: string,
) => {
  
  const packageComposition = lineItem.custom?.customFieldsRaw.find(
    (f) => f.name === 'packageComposition',
  )?.value

  // Note: The value in this custom field is a string!
  const inscriptionText: string = lineItem.custom?.customFieldsRaw.find(
    (f) => f.name === 'inscriptionText',
  )?.value

  const patientName: string = lineItem.custom?.customFieldsRaw.find(
    (f) => f.name === 'patientName',
  )?.value

  const subscription: string = lineItem.custom?.customFieldsRaw.find(
    (f) => f.name === 'cbPricePlanId',
  )?.value

  const isChargebeeSubscription = lineItem.custom?.customFieldsRaw.find(
    (f) => f.name === 'is_subscription',
  )?.value

  return [
    {
      addLineItem: {
        sku: lineItem.variant.sku,
        quantity,
        ...(packageComposition || inscriptionText || subscription || patientName || isChargebeeSubscription
          ? {
            custom: {
              typeKey: 'line-item-custom-fields',
              fields: [
                packageComposition
                  ? {
                    name: 'packageComposition',
                    value: JSON.stringify(packageComposition),
                  }
                  : null,
                inscriptionText
                  ? {
                    name: 'inscriptionText',
                    // The array has to be stored as string in Commercetools, hence we have to stringify a string
                    value: JSON.stringify(inscriptionText),
                  }
                  : null,
                patientName
                  ? {
                    name: 'patientName',
                    // The array has to be stored as string in Commercetools, hence we have to stringify a string
                    value: JSON.stringify(patientName),
                  }
                  : null,
                subscription
                  ? {
                    name: 'cbPricePlanId',
                    value: JSON.stringify(subscription),
                  }
                  : null,
                isChargebeeSubscription ? {
                  name: 'is_subscription',
                  value: JSON.stringify(isChargebeeSubscription),
                }
                  : null,
              ].filter(isValue),
            },
          }
          : {}),
        ...(priceChannelId
          ? {
            distributionChannel: {
              id: priceChannelId,
            },
          }
          : {}),
        ...(stockChannelId
          ? {
            supplyChannel: {
              id: stockChannelId,
            },
          }
          : {}),
      },
    },
  ]
}

const addToCartAction = (
  variant: ProductVariant,
  quantity: number,
  priceChannelId: string,
  stockChannelId: string,
  cart: Cart | undefined,
  subscriptionId?: string,
  patientName?: string,
) => {
  const relatedLineItem = cart?.lineItems.find(
    (i) => i.variant.sku === variant.sku,
  )

  /**
   * If the cart already contains the product, and an inscriptionText is set,
   * we have to add custom field on this action too.
   */
  const inscriptionText = getCustomValue(
    relatedLineItem?.custom?.customFieldsRaw,
    'inscriptionText',
  )

  // Tackle the possibility for multiple line item custom field definitions
  const customFieldsProp = {
    custom: {
      typeKey: 'line-item-custom-fields',
      fields: [],
    },
  }


  /* istanbul ignore next */
  if (inscriptionText) {
    customFieldsProp.custom.fields.push({
      name: 'inscriptionText',
      // The array has to be stored as string in Commercetools, hence we have to stringify a string
      value: JSON.stringify(inscriptionText),
    })
  }

  if (subscriptionId) {
    customFieldsProp.custom.fields.push({
      name: 'cbPricePlanId',
      value: JSON.stringify(subscriptionId),
    })
  }

  /* istanbul ignore next */ 
  if (patientName) {
    customFieldsProp.custom.fields.push({
      name: 'patientName',
      value: JSON.stringify(patientName),
    })
  }

  return {
    addLineItem: {
      sku: variant.sku,
      quantity,
      ...(priceChannelId
        ? {
          distributionChannel: {
            id: priceChannelId,
          },
        }
        : {}),
      ...(stockChannelId
        ? {
          supplyChannel: {
            id: stockChannelId,
          },
        }
        : {}),
      ...(customFieldsProp.custom.fields.length ? customFieldsProp : {}),
    },
  }
}


