import { Grid, Typography } from '@danone-global/internal/react/components/ui'
import { Theme, useCore } from '@danone-global/internal/react/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React from 'react'
import { FormattedMessage } from 'react-intl'

import { formatMoney } from './utils/format-money'

interface PriceInterface {
  centAmount: number

  currencyCode: string

  fractionDigits: number

  showAsterisk?: boolean

  contentQuantity?: number

  measurementUnit?: string
}

export interface Props extends PriceInterface {
  inParts?: boolean

  className?: string

  additionalPrice?: PriceInterface

  small?: boolean

  showFreeLabel?: boolean

  testId?: string

  /**
   * Inherit font size
   */
  inherit?: boolean

  tbd?: boolean
}

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      fontFamily: theme.typography.fontFamily,
      fontWeight: theme.typography.fontWeightLight,
      fontSize: theme.utils.getSize(18),
      lineHeight: theme.utils.getSize(18, { forceUnit: true }),
    },

    additionalPrice: {
      fontSize: theme.utils.getSize(14),
      lineHeight: theme.utils.getSize(14, { forceUnit: true }),
      textDecoration: 'line-through',
    },

    small: {
      fontSize: theme.utils.getSize(16),
      lineHeight: theme.utils.getSize(16, { forceUnit: true }),
    },

    inherit: {
      fontSize: 'inherit',
      lineHeight: 'inherit',
    },

    free: {
      color: '#53994d',
    },

    discount: {
      color: '#53994d',
    },

    inParts: {
      lineHeight: 1,
      display: 'flex',
      alignItems: 'flex-start',
    },

    currency: {
      fontSize: theme.utils.getSize(10),
      marginTop: theme.spacing(0.2),
    },

    main: {
      fontWeight: theme.typography.fontWeightBold,
      margin: theme.spacing(0, 0.2),
    },

    sub: {
      fontSize: theme.utils.getSize(10),
    },

    withAdditional: {
      color: theme.palette.secondary.main,
    },

    withPricePerUnit: {
      fontSize: theme.utils.getSize(10),
      color: 'gray',
    },

    pricePerUnit: {
      padding: theme.spacing(1, 0),
    },
  }),
  { name: 'Price' },
)

export const Price: React.FC<Props> = ({
  currencyCode,
  fractionDigits,
  centAmount,
  additionalPrice,
  showAsterisk = false,
  inParts = false,
  small = false,
  inherit = false,
  showFreeLabel = false,
  className = '',
  testId = 'price',
  contentQuantity,
  measurementUnit,
  tbd = false,
}) => {
  const { localeConfig, features } = useCore()
  const classes = useStyles()

  const amountWithSymbol = formatMoney(
    localeConfig.locale,
    currencyCode,
    centAmount,
    fractionDigits,
  )

  if (features.hidePrices) {
    return (
      <div
        className={clsx('dtc-common-price__wrapper', classes.root)}
        data-testid={testId}
      ></div>
    )
  }

  if (inParts) {
    const [symbol, amount] = amountWithSymbol.split(' ')
    const [main, sub] = amount.split(amountWithSymbol.includes('.') ? '.' : ',')

    return (
      <div
        className={clsx('dtc-common-price__wrapper', classes.root)}
        data-testid={testId}
      >
        <span
          className={clsx('dtc-common-price__current-price', classes.inParts)}
        >
          <span className={classes.currency}>{symbol}</span>
          <span className={clsx(classes.main, className)}>{main}</span>
          <span className={classes.sub}>{sub}</span>
        </span>

        {showAsterisk && <span>*</span>}

        {additionalPrice && (
          <Price
            {...additionalPrice}
            className={clsx(
              'dtc-common-price__additional-price',
              classes.additionalPrice,
            )}
            showAsterisk={showAsterisk}
            testId={`${testId}-additional`}
            inParts
          />
        )}
        {features.showPricePerUnit && contentQuantity > 0 && (
          <>
            {pricePerUnit(
              centAmount,
              contentQuantity,
              currencyCode,
              fractionDigits,
              measurementUnit,
            )}
          </>
        )}
      </div>
    )
  }

  return (
    <div
      className={clsx(
        'dtc-common-price__wrapper',
        classes.root,
        {
          [classes.small]: small,
          [classes.free]: centAmount === 0 && showFreeLabel,
          [classes.discount]: centAmount < 0,
          [classes.withAdditional]: !!additionalPrice,
          [classes.inherit]: inherit,
        },
        className,
      )}
      data-testid={testId}
    >
      <span className="dtc-common-price__current-price">
        {tbd ? (
          <FormattedMessage defaultMessage="TBD" id="my-order.tbd" />
        ) : centAmount === 0 && showFreeLabel ? (
          <FormattedMessage defaultMessage="free" id="price.free" />
        ) : (
          <>
            {amountWithSymbol}
            {showAsterisk && '*'}
          </>
        )}
      </span>

      {additionalPrice && (
        <Price
          {...additionalPrice}
          className={clsx(
            'dtc-common-price__additional-price',
            classes.additionalPrice,
          )}
          showAsterisk={showAsterisk}
          testId={`${testId}-additional`}
        />
      )}
      {features.showPricePerUnit && contentQuantity > 0 && (
        <>
          {pricePerUnit(
            centAmount,
            contentQuantity,
            currencyCode,
            fractionDigits,
            measurementUnit,
          )}
        </>
      )}
    </div>
  )
}

export default React.memo(Price)

export const pricePerUnit = (
  centAmount: number,
  contentQuantity: number,
  currencyCode: string,
  fractionDigits: number,
  measurementUnit: string,
) => {
  const classes = useStyles()
  const { localeConfig } = useCore()
  const unitPrice = centAmount / contentQuantity
  const unitAmountWithSymbol = formatMoney(
    localeConfig.locale,
    currencyCode,
    unitPrice,
    fractionDigits,
  )

  const { format } = new Intl.NumberFormat(localeConfig.locale, {
    maximumFractionDigits: fractionDigits,
  })

  const contentquantity = format(contentQuantity)

  const [amount, symbol] = unitAmountWithSymbol.split(' ')

  return (
    <Grid
      className={clsx('dtc-common-price__price-per-unit', classes.pricePerUnit)}
    >
      <Typography
        className={classes.withPricePerUnit}
        style={{ margin: '0px' }}
      >
        <FormattedMessage
          defaultMessage="{contentquantity} {measurementUnit} ({amount} {symbol} je 1 {measurementUnit})"
          id="price.price-per-unit"
          values={{
            contentquantity,
            measurementUnit,
            amount,
            symbol,
          }}
        />
      </Typography>
    </Grid>
  )
}
