import {
  LineItem as CtLineItem,
  LineItemMode,
} from '@danone-global/ct/interfaces'
import { TrashIcon } from '@danone-global/internal/react/components/icons'
import Price from '@danone-global/internal/react/components/price'
import { pricePerUnit } from '@danone-global/internal/react/components/price'
import {
  CtAsset,
  CtImage,
  Grid,
  Hr,
  IconButton,
  Notification,
  Typography,
} from '@danone-global/internal/react/components/ui'
import {
  getCustomValue,
  LineItemSubscriptionLabel,
  priceTimesQuantity,
  Theme,
  useCore,
  useIsBelowBreakpoint,
} from '@danone-global/internal/react/core'
import { localisedProductName } from '@danone-global/internal/react/core'
import { useProductUrl } from '@danone-global/internal/react/modules/product'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'

import LineItemControls from './controls'
import {
  isProductAvailableForSubscription,
  useUpdateQuantity,
} from './line-item.hooks'
import {
  InscriptionInputs,
  InscriptionSummary,
  PatientInputs,
  PatientNameNotesSummary,
} from './line-item.inscriptions'

interface VariantLineItemProperties {
  name: string
  quantity: number
  sku: string
}

export interface Props {
  variant?: 'default' | 'quick-view' | 'checkout' | 'checkout-mobile'
  lineItem: CtLineItem
  withAnalytics?: boolean
  isLastItem?: boolean
  errorMessage?: string
}

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      position: 'relative',
      display: 'flex',
      wordBreak: 'break-word',
      marginBottom: theme.spacing(2),
      flexWrap: 'wrap',

      '$quick-view &': {
        paddingRight: theme.spacing(2),
      },

      [theme.breakpoints.down('lg')]: {
        flexDirection: 'column',
      },
    },

    titleContainer: {},

    imageContainer: {
      flex: 0,
      display: 'flex',
      marginRight: theme.spacing(2),
      alignSelf: 'center',

      '& img': {
        minHeight: '60px !important',
      },

      '$checkout &': {
        [theme.breakpoints.down('md')]: {
          marginRight: 0,
          backgroundColor: theme.palette.common.white,
          borderRadius: theme.shape.borderRadius(),

          '& img': {
            height: '100% !important',
            padding: theme.spacing(),
          },
        },

        [theme.breakpoints.up('md')]: {
          '& img': {
            backgroundColor: theme.palette.common.white,
            borderRadius: theme.shape.borderRadius(),
            padding: theme.spacing(),
          },
        },
      },
    },

    default: {},

    'quick-view': {},

    checkout: {
      marginTop: theme.spacing(2),

      [theme.breakpoints.down('md')]: {
        alignItems: 'center',
      },
    },

    'checkout-mobile': {
      marginTop: theme.spacing(2),
    },

    nameContainer: {
      '$default &': {
        alignItems: 'center',
        paddingRight: theme.spacing(3.5),

        [theme.breakpoints.mobile()]: {
          alignItems: 'flex-start',
          flexDirection: 'column',
        },
      },

      '$checkout &': {
        [theme.breakpoints.down('md')]: {
          display: 'none',
        },
      },
    },

    nameContainerCheckoutGiftLine: {
      '$default &, $checkout-mobile &': {
        [theme.breakpoints.mobile()]: {
          flexWrap: 'nowrap',
          flexDirection: 'row',

          '& $titleContainer': {
            flex: '0 1 auto',
          },

          '& $subContainer': {
            flex: '0 0 auto',
            width: 'auto',
            marginTop: 0,
            alignItems: 'flex-start',
          },
        },
      },
    },

    subContainer: {
      '$checkout &, $checkout-mobile &': {
        display: 'flex',
        marginTop: theme.spacing(2),
      },

      '$default &': {
        display: 'flex',

        [theme.breakpoints.mobile()]: {
          marginTop: theme.spacing(3),
          width: '100%',
          justifyContent: 'flex-end',
        },
      },

      '$quick-view &': {
        marginTop: theme.spacing(2),

        [theme.breakpoints.down('lg')]: {
          justifyContent: 'space-between',
          textAlign: 'right',
        },
      },

      '& $amountContainer': {
        alignItems: 'flex-end',
      },
    },

    productName: {},

    subscription: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(0),
      fontSize: theme.utils.getSize(14),
    },

    free: {
      color: `${theme.palette.success.main} !important`,
      paddingRight: theme.spacing(1),
    },

    priceContainer: {
      minWidth: theme.utils.getSize(110),

      '$default &': {
        textAlign: 'center',
        justifyContent: 'center',

        [theme.breakpoints.mobile()]: {
          width: 'auto',
          minWidth: 'inherit',
        },
      },

      '$quick-view &': {
        textAlign: 'right',
      },
    },

    controlsContainer: {
      '$default &': {
        padding: theme.spacing(0, 1),

        [theme.breakpoints.mobile()]: {
          padding: 0,

          '& > div': {
            justifyContent: 'flex-start',
            marginRight: theme.spacing(2),
          },
        },
      },
    },

    removeContainer: {
      [theme.breakpoints.mobile()]: {
        position: 'absolute',
        right: 0,
        top: theme.spacing(-1),
      },
    },

    amountContainer: {
      minWidth: theme.utils.getSize(90),

      '$checkout &': {
        [theme.breakpoints.down('md')]: {
          display: 'none',
        },
      },
    },

    quantityContainer: {
      marginTop: theme.spacing(2),

      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },

    viewItemLink: {
      marginTop: theme.spacing(0.5),
    },

    labelContainer: {
      display: 'flex',
      flexWrap: 'wrap',
    },

    label: {
      backgroundColor: theme.palette.secondary.main,
      padding: theme.spacing(0.25, 0.5),
      borderRadius: theme.shape.borderRadius(0.5),
      marginBottom: theme.spacing(0.5),

      '&:not(:last-child)': {
        marginRight: theme.spacing(1),
      },
    },

    labelInscription: {},

    errorNotification: {
      marginTop: theme.spacing(2),
      marginBottom: 0,
      padding: theme.spacing(1, 1.5),
    },

    variantSelectionItem: {
      '& hr': {
        marginBottom: theme.spacing(4),
      },

      '& p': {
        fontSize: theme.utils.getSize(16),
        fontWeight: theme.typography.fontWeightBold,
      },

      '& p:not(:last-child)': {
        marginBottom: theme.spacing(2),
      },

      '&& div > p:nth-child(2)': {
        color: '#45484B',
        fontWeight: theme.typography.fontWeightRegular,
        marginLeft: theme.spacing(2),
        whiteSpace: 'nowrap',
      },
    },
  }),
  { name: 'LineItem' },
)

export const LineItem: React.FC<Props> = ({
  variant = 'default',
  lineItem,
  withAnalytics = true,
  errorMessage,
}) => {
  const {
    variant: { images, sku },
    price,
  } = lineItem
  const {
    config: { chargebee, siteName },
    features,
  } = useCore()
  const classes = useStyles()
  const { localeConfig } = useCore()
  const productName = localisedProductName(lineItem, localeConfig.locale)
  const productUrl = useProductUrl(lineItem.variant.attributesRaw)
  const [cartError, setCartError] = useState('')
  const updateLineItem = useUpdateQuantity({ lineItem, withAnalytics })
  const availableForSubscription = React.useMemo(
    () => isProductAvailableForSubscription(lineItem),
    [],
  )
  const isBelowLgBreakpoint = useIsBelowBreakpoint('lg')

  const handleRemoveItem = () => updateLineItem(0)

  const packageComposition = getCustomValue(
    lineItem.custom?.customFieldsRaw,
    'packageComposition',
  )

  const variants: VariantLineItemProperties[] | null = packageComposition
    ? packageComposition.map((v) => JSON.parse(v))
    : null

  const inscriptionEnabled = getCustomValue(
    lineItem.variant?.attributesRaw,
    'inscriptionTextEnabled',
  )
  const isGiftLine = lineItem.lineItemMode === LineItemMode.GiftLineItem

  /** DTCPB-4318 - Image localization from PIM to CT */
  let assetImages = []

  const asset = lineItem?.variant?.assets?.find((i) =>
    i?.tags?.includes(localeConfig.locale),
  )

  if (
    asset &&
    asset?.custom?.customFieldsRaw?.find(
      (f) => f.name === 'imagePosition' && f.value === 0,
    )
  ) {
    assetImages = asset.sources
  }

  const centAmount =
    price.discounted?.value?.centAmount ?? price?.value?.centAmount
  const currencyCode = price?.value?.currencyCode
  const fractionDigits = price?.value?.fractionDigits
  const contentquantity = lineItem?.variant?.attributesRaw
    ?.filter((item) => item.name === 'contentquantity')
    .map((quantity) => quantity.value)
    .join('')
  const measurementUnit = lineItem?.variant?.attributesRaw
    ?.filter((item) => item.name === 'measurementUnit')
    .map((unit) => unit.value.label)
    .join('')

  return (
    <>
      <Grid
        className={clsx(classes.root, classes[variant])}
        data-testid={`line-item-${sku}`}
        flex={variant !== 'checkout'}
        justify="space-between"
        md={variant !== 'checkout' ? undefined : 12}
        xs={variant !== 'checkout' ? undefined : 3}
      >
        <Grid direction="row" flex item>
          <Grid alignContent="center" className={classes.imageContainer} item>
            {assetImages.length > 0 ? (
              <CtAsset
                height="auto"
                maxHeight="fit-content"
                width={65}
                {...assetImages[0]}
              />
            ) : images.length > 0 ? (
              <CtImage
                height="auto"
                maxHeight="fit-content"
                width={65}
                {...images[0]}
              />
            ) : (
              <div style={{ width: '65px' }} />
            )}
          </Grid>

          <Grid
            className={clsx(
              classes.nameContainer,
              isGiftLine && classes.nameContainerCheckoutGiftLine,
            )}
            direction={variant === 'default' ? 'row' : 'column'}
            container
            flex
          >
            <Grid
              className={classes.titleContainer}
              direction="column"
              flex={variant === 'default'}
              item
            >
              <div className={classes.labelContainer}>
                {variant === 'default' &&
                chargebee &&
                lineItem.lineItemMode === LineItemMode.Standard &&
                !availableForSubscription ? (
                  <div
                    className={classes.label}
                    data-testid={`line-item-${sku}.not-available-for-subscription`}
                  >
                    <Typography
                      color="white"
                      fontWeight="medium"
                      variant="caption"
                      noGutters
                    >
                      <FormattedMessage
                        defaultMessage="Not available for subscription"
                        id="line-item.not-available-for-subscription"
                      />
                    </Typography>
                  </div>
                ) : undefined}

                {inscriptionEnabled ? (
                  <div
                    className={clsx(classes.label, classes.labelInscription)}
                    data-testid={`line-item-${sku}.not-available-for-subscription`}
                  >
                    <Typography
                      color="white"
                      fontWeight="medium"
                      variant="caption"
                      noGutters
                    >
                      <FormattedMessage
                        defaultMessage="Customizable"
                        id="line-item.customizable"
                      />
                    </Typography>
                  </div>
                ) : undefined}
              </div>

              <Typography
                className={classes.productName}
                color="primary-dark"
                fontWeight="bold"
                variant="body"
                noGutters
              >
                {productName}
              </Typography>

              {features.showPricePerUnit &&
                contentquantity.length > 0 &&
                pricePerUnit(
                  centAmount,
                  Number(contentquantity),
                  currencyCode,
                  fractionDigits,
                  measurementUnit,
                )}

              <LineItemSubscriptionLabel
                className={classes.subscription}
                color="secondary"
                lineItem={lineItem}
                variant="body"
              />

              {variant === 'default' && productUrl && (
                <Typography
                  className={classes.viewItemLink}
                  color="primary-dark"
                  component="a"
                  data-testid={`btn.line-item-${sku}.view-product`}
                  fontWeight="light"
                  href={productUrl}
                  variant="body"
                  noGutters
                >
                  <FormattedMessage
                    defaultMessage="View product"
                    id="btn.line-item.view-product"
                  />
                </Typography>
              )}
            </Grid>

            <Grid
              alignItems="center"
              className={classes.subContainer}
              direction="row"
              flex={variant === 'quick-view'}
              item
            >
              {!isGiftLine &&
                (variant.includes('checkout') ? (
                  <Grid className={classes.amountContainer} flex>
                    <Typography color="primary-dark" variant="body" noGutters>
                      <FormattedMessage
                        defaultMessage="Amount: {amount}"
                        id="checkout.line-item.amount"
                        values={{ amount: lineItem.quantity }}
                      />
                    </Typography>
                  </Grid>
                ) : (
                  <Grid className={classes.controlsContainer} flex>
                    <LineItemControls
                      setCartError={setCartError}
                      withAnalytics={withAnalytics}
                      {...lineItem}
                    />
                  </Grid>
                ))}

              {isGiftLine ? (
                <Grid justify="flex-end" container>
                  <Typography className={classes.free} noGutters>
                    <FormattedMessage
                      defaultMessage="Free"
                      id="checkout.line-item.free"
                    />
                  </Typography>
                </Grid>
              ) : (
                variant !== 'checkout' && (
                  <Grid
                    className={classes.priceContainer}
                    justify="flex-end"
                    container
                  >
                    <Price
                      {...priceTimesQuantity(
                        price.discounted?.value ?? price.value,
                        lineItem.quantity,
                      )}
                      additionalPrice={
                        price.discounted
                          ? priceTimesQuantity(price.value, lineItem.quantity)
                          : undefined
                      }
                      testId={`line-item-${sku}.price`}
                      small
                    />
                  </Grid>
                )
              )}
            </Grid>

            {variant === 'default' ? (
              <Grid className={classes.removeContainer} item>
                <IconButton
                  color="primary"
                  data-testid={`btn.line-item-${sku}.remove`}
                  onClick={handleRemoveItem}
                  variant="flat"
                >
                  <TrashIcon width={14} />
                </IconButton>
              </Grid>
            ) : undefined}
          </Grid>
        </Grid>

        {variant === 'default' && siteName !== 'be-nutricia' ? (
          <InscriptionInputs lineItem={lineItem} sku={sku} />
        ) : undefined}

         {variant === 'default' && siteName === 'be-nutricia' ? (
          <PatientInputs lineItem={lineItem} sku={sku} />
        ) : undefined}

        {variant === 'checkout' ? (
          <Grid className={classes.quantityContainer}>
            <Typography color="primary-dark" variant="body" noGutters>
              {lineItem.quantity}x
            </Typography>
          </Grid>
        ) : undefined}
      </Grid>

      {errorMessage && variant === 'default' && (
        <Notification
          className={classes.errorNotification}
          indicator="top-left"
          message={errorMessage}
          testId={`line-item-${sku}.error-notification`}
          error
        />
      )}

      {variant === 'checkout-mobile' && siteName !== 'be-nutricia' ? (
        <InscriptionSummary lineItem={lineItem} />
      ) : undefined}

      {variant === 'checkout' &&
      siteName !== 'be-nutricia' &&
      !isBelowLgBreakpoint ? (
        <InscriptionSummary lineItem={lineItem} />
      ) : undefined}

      {variant === 'checkout-mobile' && siteName === 'be-nutricia' ? (
        <PatientNameNotesSummary lineItem={lineItem} />
      ) : undefined}

      {variant === 'checkout' &&
      siteName === 'be-nutricia' &&
      !isBelowLgBreakpoint ? (
        <PatientNameNotesSummary lineItem={lineItem} />
      ) : undefined}

      {cartError ? (
        <Notification
          className={classes.errorNotification}
          indicator="top-left"
          message={cartError}
          error
        />
      ) : undefined}

      {variants && (
        <Grid className={classes.variantSelectionItem}>
          <Hr dashed />
          {variants.map(({ name, quantity }, index) => (
            <Grid key={index} justify="space-between" flex>
              <Typography color="primary" noGutters>
                {name}
              </Typography>
              <Typography noGutters>x {quantity}</Typography>
            </Grid>
          ))}
        </Grid>
      )}
    </>
  )
}

export default LineItem
