import {
  Cart,
  LineItem,
  LocalizedString,
  Order,
  Scalars,
} from '@danone-global/ct/interfaces'

import { Config, LocaleConfig } from '../core.config.interface'
import { Core } from '../core.provider'
import { localisedProductName } from '../core.utils'
import { productsMap } from './core.analytics-utils'

export const ANALYTICS_CATEGORY_LIMIT = 150

export const analyticsCartEvent = async (
  cart: Cart,
  config: Config,
  localeConfig: LocaleConfig,
) => ({
  action: 'GO_TO_BASKET_OVERVIEW',
  event: 'view_cart',
  ecommerce: {
    currency: cart?.totalPrice?.currencyCode,
    items: !cart
      ? []
      : await productsMap({
          lineItems: cart.lineItems,
          config,
          locale: localeConfig.locale,
        }),
    value: cart.taxedPrice
      ? cart.taxedPrice.totalGross?.centAmount / 100
      : cart.totalPrice?.centAmount / 100,
  },
})

export const analyticsLoginEvent = async (
  cart: Cart,
  userId: string | undefined,
  config: Config,
  localeConfig: LocaleConfig,
) => ({
  action: 'LOGIN',
  event: 'login_dtc',
  ecommerce: {
    cartId: cart?.id,
    items: !cart
      ? []
      : await productsMap({
          lineItems: cart.lineItems,
          config,
          locale: localeConfig.locale,
        }),
    userId,
    storeName: config.storeName,
    storeKey: config.ctStoreKey,
  },
})

export const analyticsLogoutEvent = async (
  cart: Cart,
  userId: string | undefined,
  config: Config,
  localeConfig: LocaleConfig,
) => ({
  action: 'LOGOUT',
  event: 'logout_dtc',
  ecommerce: {
    cartId: cart?.id,
    items: !cart
      ? []
      : await productsMap({
          lineItems: cart.lineItems,
          config,
          locale: localeConfig.locale,
        }),
    userId,
    storeName: config.storeName,
    storeKey: config.ctStoreKey,
  },
})

export const analyticsUserRegisteredEvent = async (
  cart: Cart | undefined,
  userId: string | undefined,
  config: Config,
  localeConfig: LocaleConfig,
) => ({
  action: 'USER_REGISTERED',
  event: 'user_registered',
  ecommerce: {
    cartId: cart?.id,
    items: !cart
      ? []
      : await productsMap({
          lineItems: cart.lineItems,
          config,
          locale: localeConfig.locale,
        }),
    userId,
    storeName: config.storeName,
    storeKey: config.ctStoreKey,
  },
})

/* istanbul ignore next */
export const analyticsManageSubscriptionEvent = async (
  cart: Cart | null,
  loggedIn: boolean,
  config: Config,
  localeConfig: LocaleConfig,
) => ({
  action: 'MANAGE_SUBSCRIPTION',
  event: 'manage_subscription',
  ecommerce: {
    cartId: cart?.id,
    items: !cart
      ? []
      : await productsMap({
          lineItems: cart.lineItems,
          config,
          locale: localeConfig.locale,
        }),
    loggedIn,
    storeName: config.storeName,
    storeKey: config.ctStoreKey,
  },
})

/* istanbul ignore next */
export const analyticsReorderClickedEvent = async (
  cart: Cart | null,
  orderDate: Scalars['DateTime'],
  userId: string | undefined,
  config: Config,
  localeConfig: LocaleConfig,
) => ({
  action: 'REORDER',
  event: 'reorder',
  ecommerce: {
    cartId: cart?.id,
    items: !cart
      ? []
      : await productsMap({
          lineItems: cart.lineItems,
          config,
          locale: localeConfig.locale,
        }),
    orderDate: new Date(orderDate).toLocaleDateString('sv'),
    storeName: config.storeName,
    storeKey: config.ctStoreKey,
    userId,
  },
})

/* istanbul ignore next */
export const analyticsCheckoutEvent = async (
  cart: Cart | undefined,
  config: Config,
  locale: string,
) => {
  return {
    action: 'GO_TO_CHECKOUT_STEP',
    event: 'begin_checkout',
    stepIndex: 3,
    ecommerce: {
      currency: cart?.totalPrice?.currencyCode,
      coupon: cart?.discountCodes?.map((i) => i.discountCode.code)?.join(','),
      items: !cart
        ? []
        : await productsMap({ lineItems: cart.lineItems, config, locale }),
      value: cart.taxedPrice
        ? cart.taxedPrice.totalGross?.centAmount / 100
        : cart.totalPrice?.centAmount / 100,
    },
  }
}

/* istanbul ignore next */
export const analyticsCheckoutGuestUserEvent = async (
  cart: Cart | undefined,
  config: Config,
  locale: string,
  emailId: string,
) => {
  return {
    action: 'ADD_GUEST_EMAIL',
    event: 'add_guest_email',
    stepIndex: 3,
    ecommerce: {
      cartId: cart?.id,
      items: !cart
        ? []
        : await productsMap({
            lineItems: cart.lineItems,
            config,
            locale,
          }),
      emailId,
      storeName: config.storeName,
      storeKey: config.ctStoreKey,
    },
  }
}

/* istanbul ignore next */
export const analyticsCheckoutShippingEvent = async (
  cart: Cart | undefined,
  config: Config,
  locale: string,
) => {
  let shippingName = ''
  if (cart?.shippingInfo?.shippingMethodName?.startsWith('{')) {
    const nameJSON = JSON.parse(cart?.shippingInfo?.shippingMethodName)
    shippingName = nameJSON[cart?.locale]
  } else {
    shippingName = cart?.shippingInfo?.shippingMethodName
  }
  return {
    action: 'ADD_SHIPPING_INFO',
    event: 'add_shipping_info',
    stepIndex: 4,
    ecommerce: {
      coupon: cart?.discountCodes?.map((i) => i?.discountCode?.code)?.join(','),
      currency: cart?.totalPrice?.currencyCode,
      items: !cart
        ? []
        : await productsMap({ lineItems: cart.lineItems, config, locale }),
      shipping_tier: shippingName,
      value: cart.taxedPrice
        ? cart.taxedPrice.totalGross?.centAmount / 100
        : cart.totalPrice?.centAmount / 100,
    },
  }
}

export const analyticsCheckoutBillingEvent = async (
  cart: Cart | undefined,
  config: Config,
  locale: string,
) => {
  return {
    action: 'ADD_BILLING_INFO',
    event: 'add_billing_info',
    stepIndex: 5,
    ecommerce: {
      coupon: cart?.discountCodes?.map((i) => i?.discountCode?.code)?.join(','),
      currency: cart?.totalPrice?.currencyCode,
      items: !cart
        ? []
        : await productsMap({ lineItems: cart.lineItems, config, locale }),
      value: cart.taxedPrice
        ? cart.taxedPrice.totalGross?.centAmount / 100
        : cart.totalPrice?.centAmount / 100,
    },
  }
}

/* istanbul ignore next */
export const analyticsCheckoutPaymentEvent = async ({
  order,
  core,
}: {
  order: Order
  core: Core
  authResult: string
}) => ({
  action: 'ADD_PAYMENT_INFO',
  event: 'add_payment_info',
  stepIndex: 6,
  ecommerce: {
    payment_type: sessionStorage.getItem('values'),
    coupon: order.discountCodes?.map((i) => i.discountCode.code)?.join(','),
    currency: order.totalPrice?.currencyCode,
    value: (order.totalPrice?.centAmount || 0) / 100,
    items: await productsMap({
      lineItems: order.lineItems,
      config: core.config,
      locale: order.locale,
    }),
  },
})

/* istanbul ignore next */
export const analyticsOrderCompletedEvent = async ({
  order,
  core,
  authResult,
}: {
  order: Order
  core: Core
  authResult: string
}) => ({
  action: 'ORDER_COMPLETED',
  event: 'purchase',
  ecommerce: {
    transaction_id: order.orderNumber,
    storeName: core.config.storeName,
    cartId: order.cart?.id,
    value: (order.totalPrice?.centAmount || 0) / 100,
    currency: order.totalPrice?.currencyCode,
    tax:
      (order.taxedPrice?.taxPortions?.reduce(
        (prev, curr) => prev + curr?.amount?.centAmount || 0,
        0,
      ) || 0) / 100,
    shipping: (order.shippingInfo?.price?.centAmount || 0) / 100,
    coupon: order.discountCodes?.map((i) => i.discountCode.code)?.join(','),
    items: await productsMap({
      lineItems: order.lineItems,
      config: core.config,
      locale: order.locale,
    }),
  },
})

/* istanbul ignore next */
const analyticsLineItem = (
  line: LineItem,
  config: Config,
  localeConfig: LocaleConfig,
) => ({
  id: line.id,
  productId: line.productId,
  name: localisedProductName(line, localeConfig.locale),
  sku: line.variant?.sku,
  quantity: line.quantity,
  category: config.analytics?.defaultCategory,
  price: line.price,
  totalPrice: line.totalPrice,
})

/* istanbul ignore next */
export const analyticsCheckoutBtntEvent = async (
  cart: Cart,
  config: Config,
  localeConfig: LocaleConfig,
  button_text,
) => ({
  action: 'CLICK_CHECKOUT',
  event: 'click_checkout',
  click_text: button_text,
  ecommerce: {
    currency: cart?.totalPrice?.currencyCode,
    items: !cart
      ? []
      : await productsMap({
          lineItems: cart.lineItems,
          config,
          locale: localeConfig.locale,
        }),
    value: cart.taxedPrice
      ? cart.taxedPrice.totalGross?.centAmount / 100
      : cart.totalPrice?.centAmount / 100,
  },
})

