import { gql } from '@apollo/client'
import type {
  Address,
  Cart,
  CartDraft,
  MutationUpdateMyCartArgs,
  MyCartUpdateAction,
  ProductVariantAvailabilityWithChannel,
  RawProductAttribute,
} from '@danone-global/ct/interfaces'
import { InventoryMode } from '@danone-global/ct/interfaces'
import {
  addressFragment,
  cartFragment,
  discountLineItemPriceFragment,
  getClient,
  moneyFragment as money,
} from '@danone-global/internal/graphql'
import * as Sentry from '@sentry/react'
import Cookies from 'universal-cookie'

import { PACKAGE_PRODUCT_KEY } from '.'
import { Config } from './core.config.interface'
import { Core } from './core.provider'

export const getGraphqlClient = (config: Config) =>
  getClient(config, {
    resolvers: customResolvers,
    typeDefs: customTypeDefs,
  })

export const GET_CATEGORY = gql`
  query getCategory($key: String) {
    category(key: $key) {
      custom {
        customFieldsRaw {
          name
          value
        }
      }
    }
  }
`

export const CHECK_INVENTORY = gql`
  query getInventoryEntries($where: String!) {
    inventoryEntries(where: $where) {
      results {
        sku
        availableQuantity
      }
    }
  }
`

export const GET_VARIANT_PRODUCT = gql`
  query getProduct($key: String, $locale: Locale) {
    product(key: $key) {
      masterData {
        current {
          name(locale: $locale)
          masterVariant {
            sku
            attributesRaw {
              name
              value
            }
          }
        }
      }
    }
  }
`

export const GET_VARIANT_PRODUCT_VARIANTS = gql`
  fragment variantData on ProductVariant {
    sku
    images {
      url
    }
    availability {
      channels {
        results {
          channel {
            id
            key
          }
          availability {
            availableQuantity
          }
        }
      }
    }
    attributesRaw {
      name
      value
    }
  }

  query getSku($ref: String) {
    product(id: $ref) {
      masterData {
        current {
          masterVariant {
            ...variantData
          }
          variants {
            ...variantData
          }
        }
      }
    }
  }
`

export const GET_CART = gql`
  query getCart($id: String!) {
    me {
      cart(id: $id) {
        ...cart
      }
    }
  }
  ${cartFragment}
`

export const GET_ACTIVE_CART = gql`
  query getActiveCart($where: String!, $sort: [String!]) {
    me {
      carts(limit: 1, where: $where, sort: $sort) {
        results {
          ...cart
        }
      }
    }
  }
  ${cartFragment}
`

export const CREATE_CART = gql`
  mutation createMyCart($draftCart: MyCartDraft!) {
    createMyCart(draft: $draftCart) {
      ...cart
    }
  }
  ${cartFragment}
`

export const UPDATE_ACTIVE_CART = gql`
  mutation updateActiveCart(
    $id: String!
    $version: Long!
    $actions: [MyCartUpdateAction!]!
  ) {
    updateMyCart(id: $id, version: $version, actions: $actions) {
      ...cart
    }
  }
  ${cartFragment}
`

export const GET_QUICK_VIEW_STATE = gql`
  query getQuickviewState {
    quickViewState @client {
      open
      skus
      packageComposition
      giftSkus
    }
  }
`

export const GET_PRODUCTS_AVAILABILITY = gql`
  query getProductsAvailability($locale: Locale!, $where: String!, $limit: Int!) {
    products(where: $where, limit: $limit) {
      results {
        productType {
          key
        }
        masterData {
          published
          current {
            name(locale: $locale)
            nameAllLocales {
              locale
              value
            }
            masterVariant {
              sku
              attributesRaw {
                name
                value
              }
              availability {
                channels {
                  results {
                    channel {
                      id
                    }
                    availability {
                      id
                      availableQuantity
                      isOnStock
                    }
                  }
                }
              }
            }
            variants {
              sku
              attributesRaw {
                name
                value
              }
              availability {
                channels {
                  results {
                    channel {
                      id
                    }
                    availability {
                      id
                      availableQuantity
                      isOnStock
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const SET_QUICK_VIEW_STATE = gql`
  mutation setQuickViewOpen(
    $open: Boolean!
    $skus: [String!]
    $giftSkus: [String!]
    $packageComposition: [String!] = null
  ) {
    setQuickViewState(
      open: $open
      skus: $skus
      giftSkus: $giftSkus
      packageComposition: $packageComposition
    ) @client {
      open
      skus
      packageComposition
      giftSkus
    }
  }
`

export const GET_MY_ORDERS = gql`
  query getMyOrders(
    $locale: Locale!
    $limit: Int!
    $offset: Int!
    $sorting: String!
    $where: String
    $currency: Currency!
    $country: Country!
  ) {
    me {
      orders(limit: $limit, offset: $offset, sort: [$sorting], where: $where) {
        total
        results {
          id
          cart {
            id
          }
          customerEmail
          locale
          orderNumber
          createdAt
          orderState
          shipmentState
          totalPrice {
            ...money
          }
          taxedPrice {
            totalGross {
              centAmount
              currencyCode
              fractionDigits
            }
            taxPortions {
              amount {
                ...money
              }
            }
            totalTax {
              ...money
            }
          }

          discountCodes {
            state
            discountCode {
              id
              code
              cartDiscounts {
                id
              }
              isActive
              nameAllLocales {
                locale
                value
              }
            }
          }
          lineItems {
            discountedPricePerQuantity {
              quantity
              discountedPrice {
                ...discount
              }
            }
            id
            productId
            productType {
              key
              name
            }
            lineItemMode
            quantity
            price {
              value {
                ...money
              }
              discounted {
                value {
                  ...money
                }
              }
            }
            totalPrice {
              ...money
            }
            name(locale: $locale)
            nameAllLocales {
              locale
              value
            }
            variant {
              sku
              images {
                url
              }
              price(currency: $currency, country: $country) {
                value {
                  ...money
                }
              }
              attributesRaw {
                name
                value
              }
            }
            custom {
              customFieldsRaw(
                includeNames: ["packageComposition", "cbPricePlanId", "patientName"]
              ) {
                name
                value
              }
            }
          }
          shippingInfo {
            price {
              ...money
            }
            deliveries {
              parcels {
                trackingData {
                  trackingId
                }
              }
            }
          }
          paymentInfo {
            payments {
              paymentMethodInfo {
                method
                name(locale: $locale)
              }
            }
          }
          custom {
            customFieldsRaw {
              name
              value
            }
          }
          returnInfo {
            items {
              paymentState
            }
          }
          shippingAddress {
            ...address
          }
          billingAddress {
            ...address
          }
        }
      }
    }
  }

  ${money}
  ${addressFragment}
  ${discountLineItemPriceFragment}
`

export const GET_MY_PROFILE = gql`
  query getMyProfile {
    me {
      customer {
        id
        email
        externalId
        custom {
          customFieldsRaw {
            name
            value
          }
        }
        defaultBillingAddress {
          ...address
        }
        defaultShippingAddress {
          ...address
        }
        externalId
      }
    }
  }
  ${addressFragment}
`

export const getActiveCartVariables = (country: string) => ({
  where: `cartState = "Active" and country = "${country}"`,
  sort: ['lastModifiedAt desc'],
})

export const createCart = async (core: Core): Promise<Cart> => {
  const apolloClient = getGraphqlClient(core.config)

  const inventoryMode =
    core.config.ctStoreKey === 'US'
      ? InventoryMode.ReserveOnOrder
      : InventoryMode.None

  const draftCart: CartDraft = {
    currency: core.localeConfig.currency,
    country: core.localeConfig.country,
    locale: core.localeConfig.locale,
    inventoryMode,
    shippingAddress: {
      country: core.localeConfig.country,
    },
    store: {
      key: core.config.ctStoreKey,
    },
  }

  const {
    data: { createMyCart },
  } = await apolloClient.mutate({
    mutation: CREATE_CART,
    variables: {
      draftCart,
    },
    // Make sure the cache is populated correctly
    update: (cache, { data: { createMyCart } }) => {
      cache.writeQuery({
        query: GET_ACTIVE_CART,
        variables: getActiveCartVariables(core.localeConfig.country),
        data: { me: { __typename: 'Me', carts: { results: [createMyCart] } } },
      })
    },
  })

  const cookies = new Cookies()
  const gaCookie = cookies.get('_ga')

  // Immediate set some basic fields (We cannot do this in the create cart as we dont now the custom.typeKey)
  const {
    data: { updateMyCart },
  } = await apolloClient.mutate({
    mutation: UPDATE_ACTIVE_CART,
    variables: {
      id: createMyCart.id,
      version: createMyCart.version,
      actions: [
        {
          setCustomField: {
            name: 'sourceDomain',
            value: JSON.stringify(window.location.host),
          },
        },
        // Set the ga cookie if there is one
        gaCookie && {
          setCustomField: {
            name: 'analyticsId',
            value: JSON.stringify(gaCookie),
          },
        },
      ].filter(Boolean) as MyCartUpdateAction[],
    } as MutationUpdateMyCartArgs,
  })

  Sentry.captureMessage(`Source Domain name is ${JSON.stringify(window.location.host)}`)

  return updateMyCart
}

export const getActiveCart = async (core: Core, acceptCache = true) => {
  const apolloClient = getGraphqlClient(core.config)

  const result = await apolloClient.query({
    query: GET_ACTIVE_CART,
    variables: getActiveCartVariables(core.localeConfig.country),
    // If cache is accepted then use the default
    fetchPolicy: acceptCache ? undefined : 'network-only',
  })

  const cart = result.data.me.carts.results[0] as Cart | null

  /**
   * If cart locale doesn't match localeConfig, update the cart.
   *
   * This can happen in a market like ch-am, where the cart (and country)
   * should remain the same, but the locale can switch.
   */
  if (cart && cart.locale !== core.localeConfig.locale) {
    const updatedCartResult = await apolloClient.mutate({
      mutation: UPDATE_ACTIVE_CART,
      variables: {
        id: cart.id,
        version: cart.version,
        actions: [
          {
            setLocale: {
              locale: core.localeConfig.locale,
            },
          },
        ],
      } as MutationUpdateMyCartArgs,
    })

    return updatedCartResult.data.updateMyCart
  } else {
    return cart
  }
}

export const updateCart = async (
  core: Core,
  acceptCache = true,
  actions: MyCartUpdateAction[],
) => {
  const apolloClient = getGraphqlClient(core.config)
  const activeCart = await getActiveCart(core)

  await apolloClient.mutate({
    mutation: UPDATE_ACTIVE_CART,
    variables: {
      version: activeCart.version,
      id: activeCart.id,
      actions,
    },
    // If cache is accepted then use the default
    fetchPolicy: acceptCache ? undefined : 'network-only',
  })
}

export type Customer = {
  id: string
  email: string
  externalId: string
  defaultBillingAddress?: Address
  defaultShippingAddress?: Address
  custom?: {
    customFieldsRaw?: []
  }
}

export const getMyProfile = async (
  config: Config,
  acceptCache = true,
): Promise<Customer | null> => {
  const apolloClient = getGraphqlClient(config)

  const result = await apolloClient
    .query({
      query: GET_MY_PROFILE,
      // If cache is accepted then use the default
      fetchPolicy: acceptCache ? undefined : 'network-only',
    })
    .catch((e) => {
      Sentry.captureEvent(e)
      return undefined
    })

  return result?.data?.me?.customer
}

export const getProductsAvailability = async (
  core: Core,
  acceptCache = true,
  skus: string[],
  isProductId = false,
) => {
  const apolloClient = getGraphqlClient(core.config)
  let queryData
  if (isProductId) {
    queryData = {
      where: `(id in (${skus.map((productId) => `"${productId}"`).join(',')}))`,
      limit: 50,
      locale: core.localeConfig.locale,
    }
  } else {
    queryData = {
      where: `masterData(current(masterVariant(sku in (${skus
        .map((sku) => `"${sku}"`)
        .join(',')}))))`,
      limit: 50,
      locale: core.localeConfig.locale,
    }
  }
  const { data } = await apolloClient.query({
    query: GET_PRODUCTS_AVAILABILITY,
    variables: queryData,
    // If cache is accepted then use the default
    fetchPolicy: acceptCache ? undefined : 'network-only',
  })

  const variants = data?.products?.results.map(
    (item) => item.masterData.current.masterVariant,
  ) as {
    sku: string    
    availability: {
      channels: { results: ProductVariantAvailabilityWithChannel[] }
    }
    attributesRaw: RawProductAttribute[]
  }[]

  return variants.map(({ sku, availability, attributesRaw }) => ({
    sku,
    productName: data?.products?.results?.find(
      (i) => i.masterData?.current?.masterVariant?.sku === sku,
    ).masterData.current.nameAllLocales.find(name => name.locale === core.localeConfig.locale).value,
    availability: availability.channels.results.find(
      (r) => r.channel.id === core.config.defaultChannelId,
    )?.availability,
    skusReplaced: attributesRaw.find(
      (newSkus) => newSkus.name === 'replacedBySKU',
    ),
    preventReorder: attributesRaw.find(
      (item) => item.name === 'preventReorder',
    ),
    attributesRaw,
    isPublish: data?.products?.results?.find(
      (i) => i.masterData?.current?.masterVariant?.sku === sku,
    ).masterData?.published,

    isPackageProduct:
      data?.products?.results?.find(
        (i) => i.masterData?.current?.masterVariant?.sku === sku,
      )?.productType?.key === PACKAGE_PRODUCT_KEY,
      
    availabilityData: data?.products?.results
  }))
}

export const customResolvers = {
  Mutation: {
    setQuickViewState: (_parent, state, { cache }) => {
      cache.writeQuery({
        query: GET_QUICK_VIEW_STATE,
        data: {
          quickViewState: state,
        },
      })

      return state
    },
  },
}

export const customTypeDefs = gql`
  type QuickViewState {
    open: Boolean!
    skus: [String!]
    giftSkus: [String!]
  }

  extend type Query {
    setQuickViewState: QuickViewState
  }

  extend type Mutation {
    getQuickViewState(open: Boolean!, skus: [String!]): QuickViewState
  }
`

export const CREATE_ORDER_FROM_CART = gql`
  mutation createMyOrderFromCart(
    $draft: OrderMyCartCommand!
    $storeKey: KeyReferenceInput!
  ) {
    createMyOrderFromCart(draft: $draft, storeKey: $storeKey) {
      id
    }
  }
`

export const GET_CUSTOM_OBJECTS = gql`
  query getObjects {
    customObject(
      container: "customerDropdownFieldsValues"
      key: "customerDropdownFieldsValues"
    ) {
      value
    }
  }
`
