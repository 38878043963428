import { ApolloQueryResult, OperationVariables, useQuery } from '@apollo/client'
import type { Cart } from '@danone-global/ct/interfaces'

import { GET_ACTIVE_CART, getActiveCartVariables } from '../core.graphql'
import { useCore } from './useCore'

export const useCart = (): [
  Cart | undefined,
  boolean,
  (
    variables?: Partial<OperationVariables>,
  ) => Promise<ApolloQueryResult<{ me: { carts: { results: Cart[] } } }>>,
] => {
  const { localeConfig } = useCore()
  const { data, loading, refetch } = useQuery(GET_ACTIVE_CART, {
    variables: getActiveCartVariables(localeConfig?.country),
  })

  return [data?.me?.carts?.results[0], loading, refetch]
}
